import styled from 'styled-components'
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import SectionHero from "../components/section-hero";
import Contact from "../components/contact"
import Locations from "../components/locations"

import YoastSeoParts from '../fragments/seo';

class ContactUs extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    const { contactUsCopy, caHeadquarters, usHeadquarters } = this.props.data.wp.acfOptionsContact.themeOptionsContact;
    const { pageAccentColor } = page.template.templateContactUs;
    const locations = this.props.data.allWpLocation.nodes;
    return (
      <Layout>
        <PageWrapper accentColor={pageAccentColor || '#21c6be'}>
          <Head title={seo.title} description={seo.metaDesc} meta={seo} />
          <SectionHero section="landing" title={page.title}/>
          <Locations locations={locations}/>
          <Contact usHeadquarters={usHeadquarters} caHeadquarters={caHeadquarters} copy={contactUsCopy} />
        </PageWrapper>
      </Layout>
    )
  }
}

const PageWrapper = styled.div`
  .defaultHero .dots span {
    color: ${({ accentColor }) => accentColor};
  }

  .defaultHero {
    border-top-color: ${({ accentColor }) => accentColor};
  }

  hr {
    border-color: ${({ accentColor }) => accentColor} !important;
  }
`

export default ContactUs

ContactUs.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      link
      id
      seo {
        ...seoParts
      }
      template {
        ... on WpTemplate_ContactUs {
          templateName
          templateContactUs {
            pageAccentColor
          }
        }
      }
    }
    wp {
      acfOptionsContact {
        themeOptionsContact {
          contactUsCopy
          caHeadquarters {
            address
            address2
            city
            faxNumber
            mapIframe
            phoneLink
            phoneText
            postalCode
            province
          }
          usHeadquarters {
            address
            address2
            city
            faxNumber
            mapIframe
            phoneLink
            phoneText
            state
            zip
          }
        }
      }
    }

    allWpLocation(sort: {fields: title}) {
      nodes {
        id
        title
        cptLocations {
          faxNumber
          phoneNumber
          address {
            city
            line1
            stateProvence
            line2
            zipPostalCode
          }
        }
      }
    }
  }
`
